<template>
  <div>
      <AppBar />
      <div class="padding"></div>
      <div class="text-content row justify-content-center align-items-center">
          <div class="col-md-8 content m-4">
            <p class="">To submit content to AfCFTA Visions pages, you must first
                acquire an AfCFTA Number and AfCFTA App account. 
                AfCFTA Number is the continental ID system for making AfCFTA connections 
                and interacting with others to create <a class="text-danger tex-underline" target="_blank" href="https://au.int/en/agenda2063/overview"><strong>the</strong> Africa We Want</a>. The AfCFTA Visions Standard terms and conditions apply.
                 </p>
                 <p class="my-5" v-if="!service">
                     <button @click="login" class="myBtn">Continue To Login</button>
                 </p>
                 <p class="my-5" v-if="service">
                     <button class="myBtn" @click="register">Continue To Create An Account</button>
                 </p>
          </div>
      </div>
      <FinalFooter />
  </div>
</template>

<script>
import AppBar from '../components/New/NavBar'
import FinalFooter from '../components/New/Footer'
import url from '../helpers/url'
import serviceurl from '../helpers/service'
export default {
    components:{
        FinalFooter,
        AppBar
    },
    data() {
        return{
            service:false
        }
    },
    methods:{
        // url() {
        //     return url()
        // },
        // serviceurl(){
        //     return serviceurl()
        // },
        login(){
            window.location.replace(`${url()}/accounts/login?${serviceurl()}`)
        },
        register(){
            window.location.replace(`${url()}/accounts/register`)
        }
    },
    created() {
          const query = this.$route.query.service 
          query == 'register' ? this.service = true : null;
    }
}
</script>

<style>
.padding{
    margin-top:100px;
    height: 100px;
}

.content{
    margin:150px 0;
}

.myBtn:hover{
    text-decoration: underline;
    border:1px solid #ba2428;
    font-size:1.1em;
}
a{
    text-decoration: none;
}
</style>